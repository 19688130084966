import type { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { encodePassphrase, randomString } from '../lib/client-utils';
import styles from '../styles/Home.module.css';
import axios from 'axios';

function CustomConnectionTab() {
  const router = useRouter();

  const [e2ee, setE2ee] = useState(false);
  const [sharedPassphrase, setSharedPassphrase] = useState(randomString(64));
  const [name, setName] = useState("");
  const [roomName, setRoomName] = useState("12345");

  // Read roomId from URL query parameters and set it automatically
  useEffect(() => {
    const roomId = router.query.roomId as string;
    if (roomId) {
      setRoomName(roomId);
    }
  }, [router.query.roomId]);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async(event) => {
    event.preventDefault();
    const serverUrl = 'wss://livekit.ar-toir.ru';
    const url_token = 'https://api.ar-toir.ru/token_generator_custom';

    const response = await axios.post(url_token, {
      name: name,
      roomId: roomName
    });
    const token = response.data;
    router.push(`/custom/?liveKitUrl=${serverUrl}&token=${token}&name=${encodeURIComponent(name)}&roomName=${encodeURIComponent(roomName)}`);
};


  return (
    <form className={styles.tabContent} onSubmit={onSubmit}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
          <label htmlFor="name" className='w-[90px]'>Имя</label>
          <input
            className='border p-1 px-3 rounded'
            id="name"
            type="text"
            value={name}
            onChange={(ev) => setName(ev.target.value)}
            required
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
          <label className='w-[90px]' htmlFor="roomName">ID комнаты</label>
          <input
            id="roomName"
            className='border p-1 px-3 rounded'
            type="text"
            value={roomName}
            onChange={(ev) => setRoomName(ev.target.value)}
            required
          />
        </div>
        {/* <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
          <input
            id="use-e2ee"
            type="checkbox"
            checked={e2ee}
            onChange={(ev) => setE2ee(ev.target.checked)}
          ></input>
          <label htmlFor="use-e2ee">Enable end-to-end encryption</label>
        </div> */}
       
        <button
          style={{ paddingInline: '1.25rem', width: '100%' }}
          className="lk-button"
          type="submit"
        >
          Подключиться
        </button>
      </div>
    </form>
  );
}

export const getServerSideProps: GetServerSideProps<{ tabIndex: number }> = async ({
  query,
  res,
}) => {
  res.setHeader('Cache-Control', 'public, max-age=7200');
  return { props: { tabIndex: 0 } };
};

const Home = ({ tabIndex }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  return (
    <>
      <main className={styles.main} data-lk-theme="default">
        <div className="header">
          <img src="/images/livekit-meet-home.svg" alt="LiveKit Meet" width="360" height="45" />
        </div>
        <CustomConnectionTab />
      </main>
    </>
  );
};

export default Home;
